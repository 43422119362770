<template>
  <figure class="icon check-symbol-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 6L9 17L4 12"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CheckSymbolIcon",
};
</script>
