<template>
  <label
    class="check"
    :class="{
      'check--disabled': disabled,
      'check--error': error,
      'check--checked': checked,
      'check--indeterminate': indeterminate,
    }"
  >
    <span class="check__state">
      <Component :is="state" v-if="state" />
    </span>
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      :indeterminate="indeterminate"
      @change="$emit('change', $event.target.checked)"
    />
    <slot />
  </label>
</template>

<script>
import CheckSymbolIcon from "../svg/CheckSymbolIcon";
import MinuxSymbolIcon from "../svg/MinusSymbolIcon";

export default {
  name: "CheckComponent",
  components: { MinuxSymbolIcon, CheckSymbolIcon },
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    error: Boolean,
    checked: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
  },
  computed: {
    state() {
      if (this.indeterminate) {
        return MinuxSymbolIcon;
      }
      return CheckSymbolIcon;
    },
  },
};
</script>

<style lang="stylus">
.check {
  display grid
  grid-template-columns 20px auto
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  grid-gap 8px
  user-select none
  font-size: 0.8750em; // 14px
  line-height: 18px;
  letter-spacing: var(--letter_spacing);

  input {
    absolute left top
    visibility hidden
  }

  &--error {
    color var(--error_red) !important

    .check__state {
      border-color var(--error_red) !important
    }
  }

  &--disabled {
    color var(--main_light_gray)

    .check__state {
      background: var(--input_default_border);
      border: 1px solid var(--main_light_gray);
    }

    &.check--indeterminate
    &.check--checked {
      .check__state {
        background: var(--main_light_gray);
        border: 1px solid var(--main_light_gray);
      }

      svg path {
        stroke var(--main_white)
      }
    }
  }

  &--indeterminate:not(.check--disabled)
  &--checked:not(.check--disabled) {
    color var(--main_dark_gray)

    .check__state {
      background var(--main_accent)
      border-color var(--main_accent)

      svg path {
        stroke var(--main_white)
      }
    }
  }

  &__state {
    width 20px
    height 20px
    background: var(--main_white);
    border: 1px solid var(--main_light_gray);
    box-sizing: border-box;
    border-radius: var(--button_small_radius);
    display flex
    align-items center
    justify-content center
    transition .3s

    .icon {
      width 16px
      height 16px
      display flex
      align-items center
      justify-content center

      svg path {
        transition .3s
        stroke transparent
      }
    }
  }
}
</style>
